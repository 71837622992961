const data = {
    'Language': '语言',
};

export const LangZhCn = {
    name: '中文(简体)',
    type: 'zh-cn',
    lang: (key: string) => {
        return data[key] ? data[key] : '';
    }
};