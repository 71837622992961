import Component from 'vue-class-component';
import { AppVue } from '../../app.vue';
import { AppNet } from '../../app.net';
import './home.scss';
import { AppConfig } from '../../app.config';
import { AppUtil } from '../../app.util';

@Component({
    template: require('./home.html'),
    components: {
    },
    props: {},
})
export class PageHome extends AppVue {
    jump(url){
        // sessionStorage.setItem('pg','1');
        this.hrefTo(url);
    }
    created() {
            // alert("screen.width="+screen.width)
    }
}
