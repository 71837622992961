import * as localforage from 'localforage';
import { AppState } from './app.state';

declare let globalBaseUrl: any;

class AppConfigBase {
    /********** 版本以及网络配置 **********/
    private $debug = false;
    private $projectVersion = 101;
    private $projectVersionCode = '0.1.1';
    private $bundleId = 'com.changke.adm.mid';
    private $openCDN = true;

    // ------------------

    private debugBaseUrl = 'http://localhost:8080';
    private releaseBaseUrl = 'http://syt.3g3e.com.cn';

    /********** 基本 **********/

    // 允许外部(不通过默认Cookie)传递PHPSESSION/TOKEN会话ID值
    private $allowOutAuthParam = false;
    private $sessKey = 'PHPSESSID'; // ['token', 'PHPSESSID'];
    private $tokenToHeader = ''; // 'Authorization'
    private $sessUserID = 'uid';

    private $session: any;
    private $sett: any;
    private $sessToken: any;
    private $lang = 'en';
    private $gobackFuncs = [];
    private static $self: AppConfigBase = null;

    constructor() {

    }

    public static getInstance(): AppConfigBase {
        if (AppConfigBase.$self) {
            return AppConfigBase.$self;
        }
        this.$self = new AppConfigBase();
        return this.$self;
    }

    public async init() {
        if (!this.canStorage()) {
            return;
        }

        localforage.config({
            name: this.$bundleId,
            storeName: this.$bundleId + '_' + this.$projectVersionCode,
        });

        const isess = await this.get('session');
        if (isess) {
            this.$session = isess;
        }

        const lang = await this.get('lang');
        if (lang) {
            this.$lang = lang;
        }

        const selectKey = await this.get('selectKey');
        AppState.commit('setSessionStatus', { sess: isess, isLogin: this.isLogin() });
        AppState.commit('setSelectedKey', selectKey);
        AppState.commit('setLang', this.$lang);
    }


    /********** 方法 **********/
    public projectVersion() {
        return this.$projectVersion;
    }

    public projectVersionCode() {
        return this.$projectVersionCode;
    }

    public getTokenToHeader() {
        return this.$tokenToHeader;
    }

    public getLang() {
        return this.$lang;
    }
    public setLang(l) {
        this.$lang = l;
        this.set('lang', l);

        AppState.commit('setLang', this.$lang);
        return this.$lang;
    }

    public isDebug() {
        return this.$debug;
    }

    public getSession() {
        console.log('AppConfig.getSession');
        return this.$session;
    }

    public async setSession(s) {
        if (!this.canStorage()) {
            return;
        }

        const isess = await this.set('session', s);
        this.$session = isess;

        if(!s) {
            await this.remove('SESS_TOKEN', null);
        }

        console.log('setSession', this.$session);

        AppState.commit('setSessionStatus', { sess: isess, isLogin: this.isLogin() });
    }

    public isLogin() {
        if (!this.$session) {
            return false;
        }
        if (!this.$session[this.$sessUserID]) {
            return false;
        }
        if (this.$session[this.$sessUserID] > 0) {
            return true;
        }
        return false;
    }

    public getUid() {
        if (!this.$session) {
            return 0;
        }
        if (!this.$session[this.$sessUserID]) {
            return 0;
        }
        return parseInt('' + this.$session[this.$sessUserID], 10);
    }

    public openCDN(): boolean {
        return this.$openCDN;
    }

    public getBaseUrl(ignore?) {
        let gurl;
        try {
            gurl = globalBaseUrl;
        } catch (_) { }

        if (!ignore) {
            if (this.isDebug()) {
                return gurl ? gurl : this.debugBaseUrl;
            } else {
                return gurl ? gurl : this.releaseBaseUrl;
            }
        } else {
            return this.releaseBaseUrl;
        }
    }

    /********** 存储 *********** */
    public async remove(key, fn?) {
        if (!this.canStorage()) {
            if (fn) {
                fn(false);
            }
            return;
        }
        await localforage.removeItem(key);
        if (fn) {
            fn(true);
        }
    }

    public canStorage() {
        return !!localforage;
    }

    public async set(key, pval): Promise<any> {
        if (!this.canStorage()) {
            return new Promise<any>((resolve) => {
                resolve(null);
            });
        }
        return new Promise<any>((resolve) => {
            const now = parseInt('' + Math.floor(new Date().getTime() / 1000), 10);
            localforage
                .setItem(key, { __AUTO_TIME_TAG: now, val: pval })
                .then((data) => {
                    if (data) {
                        resolve(data.val);
                    } else {
                        resolve(null);
                    }
                })
                .catch(() => {
                    resolve(null);
                });
        });
    }

    public async get(key, def?): Promise<any> {
        if (!this.canStorage()) {
            return Promise.resolve(def ? def : null);
        }
        return new Promise<any>((resolve) => {
            localforage
                .getItem(key)
                .then((data: any) => {
                    if (data) {
                        resolve(data.val);
                    } else {
                        resolve(def ? def : null);
                    }
                })
                .catch(() => {
                    resolve(def ? def : null);
                });
        });
    }

    // public async del(key): Promise<any> {
    //     if (!this.canStorage()) {
    //         return new Promise<any>((resolve) => {
    //             resolve();
    //         });
    //     }
    //     return new Promise<any>((resolve) => {
    //         localforage
    //             .removeItem(key)
    //             .then((data) => {
    //                 resolve();
    //             })
    //             .catch(() => {
    //                 resolve();
    //             });
    //     });
    // }

    public clearAll() {
        if (localforage) {
            localforage.clear();
        }
        if (this.$sessToken) {
            this.$sessToken = null;
        }
        if (this.$session) {
            this.$session = null;
        }
    }

    public async getWithTimeoutStrict(key, timeoutSecond, def?): Promise<any> {
        if (!this.canStorage()) {
            return new Promise<any>((resolve) => {
                resolve({
                    isTimeout: true,
                    data: def ? def : null,
                });
            });
        }
        return new Promise<any>((resolve) => {
            localforage
                .getItem(key)
                .then((data: any) => {
                    const now = parseInt(
                        '' + Math.floor(new Date().getTime() / 1000),
                        10
                    );
                    if (
                        !data ||
                        !data.__AUTO_TIME_TAG ||
                        data.__AUTO_TIME_TAG > now ||
                        now - data.__AUTO_TIME_TAG > timeoutSecond
                    ) {
                        resolve({
                            isTimeout: true,
                            data: def ? def : null,
                        });
                        return;
                    }
                    resolve({
                        isTimeout: false,
                        data: data.val,
                    });
                })
                .catch(() => {
                    resolve({
                        isTimeout: true,
                        data: def ? def : null,
                    });
                });
        });
    }
    public async getWithTimeout(key, timeoutSecond, def?): Promise<any> {
        const val = await this.getWithTimeoutStrict(key, timeoutSecond, def);
        return Promise.resolve(val.data);
    }

    public async setSessToken(pkg) {
        if (!pkg || !this.canStorage()) {
            return;
        }
        let tk = null;
        for (const k of Object.keys(pkg)) {
            if (k === this.$sessKey) {
                tk = {
                    key: k,
                    value: pkg[k],
                };
            }
            if (tk) {
                break;
            }
        }
        if (!tk && pkg.data) {
            for (const k of Object.keys(pkg.data)) {
                if (k === this.$sessKey) {
                    tk = {
                        key: k,
                        value: pkg.data[k],
                    };
                }
                if (tk) {
                    break;
                }
            }
        }
        if (tk) {
            console.log('setSessToken', tk);
            await this.set('SESS_TOKEN', tk);
            this.$sessToken = tk;
        }
    }

    public async getSessToken(): Promise<any> {
        if (this.$allowOutAuthParam) {
            return null;
        }
        if (this.$sessToken && this.$sessToken.key === this.$sessKey) {
            return Promise.resolve(this.$sessToken);
        }
        const tk = await this.get('SESS_TOKEN');
        if(!tk) {
            return Promise.resolve(null);
        }
        if (tk.key === this.$sessKey) {
            this.$sessToken = tk;
            return Promise.resolve(tk);
        }
        return Promise.resolve(null);
    }

    public gobackFuncPop() {
        const obj = this.$gobackFuncs.pop();
        if (!obj) {
            return null;
        }
        return obj.fn;
    }

    public gobackFuncClear(key) {
        const items = [];
        for (const item of this.$gobackFuncs) {
            if (item.key !== key) {
                items.push(item);
            }
        }
        this.$gobackFuncs = items;
    }
}

export const AppConfig = AppConfigBase.getInstance();
