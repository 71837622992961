import Vue from 'vue';
import Component from 'vue-class-component';
import router from './router/router-base';
import Antd from 'ant-design-vue';
import { AppState } from './app.state';
import { AppConfig } from './app.config';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'ant-design-vue/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './main.scss';
Vue.config.productionTip = false;

Vue.use(Antd);
Vue.use(ElementUI);

@Component({
    router,
    'store': AppState,
    template: require('./main.html'),
})
export class App extends Vue {
    data(): any {
        return {};
    }

}

// 同步启动
(async () => {

    await AppConfig.init();

    new Vue({
        render: h => h(App),
    }).$mount('#app');

})();



