import { AppConfig } from './app.config';
import jsSHA from 'jssha';

export class AppUtil {
    public static MAX_INT = 2147483647;

    public static msg($alert, msg) {
        alert(msg);
    }
    public static str(...args) {
        const strs = [];
        for (const i of Object.keys(args)) {
            strs.push(JSON.stringify(args[i]));
        }
        return strs.join('\n');
    }
    public static toSha256(str) {
        const shaObj = new jsSHA('SHA-256', 'TEXT');
        shaObj.update(str);
        return shaObj.getHash('HEX');
    }

    /****** 对象转数组 *******/
    public static objectToArray(obj) {
        const result = [];
        if (!(obj) || typeof (obj) !== 'object') {
            return result;
        }

        let j = 0;
        for (const i of Object.keys(obj)) {
            result[j] = obj[i];
            j++;
        }
        return result;
    }

    public static sleep(ms: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => resolve(), ms);
        });
    }

    public static getImagePath(path, basePath?, type?, defPath?, ignore?) {
        if (!path || path.length === 0) {
            if (defPath) {
                return defPath;
            }
            return 'assets/imgs/default_image.png';
        }
        if (path.indexOf('http://') === 0
            || path.indexOf('https://') === 0
            || path.indexOf('assets') === 0
            || path.indexOf('data:image') === 0) {
            return path;
        }
        if (path.indexOf('//') === 0) {
            return 'http:' + path;
        }
        if (path.indexOf('://') === 0) {
            return 'http' + path;
        }
        if (path.indexOf('/') === 0) {
            return AppConfig.getBaseUrl(ignore) + path;
        }
        if (basePath && type) {
            return AppConfig.getBaseUrl(ignore) + basePath + '?size=' + type + '&path=' + path;
        }
        return AppConfig.getBaseUrl(ignore) + '/' + path;
    }

    public static getUrl(path) {
        if (!path || path.length === 0) {
            return '';
        }
        if (path.indexOf('http://') === 0
            || path.indexOf('https://') === 0) {
            return path;
        }
        return AppConfig.getBaseUrl() + path;
    }

    public static deepCopy(p, cp) {
        const c = cp || {};
        for (const i of Object.keys(p)) {
            if (!p.hasOwnProperty(i)) {
                continue;
            }
            if (typeof p[i] === 'object') {
                c[i] = (p[i].constructor === Array) ? [] : {};
                this.deepCopy(p[i], c[i]);
            } else {
                c[i] = p[i];
            }
        }
        return c;
    }

    public static time(): number {
        const tm = new Date().getTime();
        return parseInt('' + Math.floor(tm / 1000), 10);
    }

    public static getTime(str): number {
        const tm = new Date(str).getTime();
        return parseInt('' + Math.floor(tm / 1000), 10);
    }

    public static makeKey(...args): string {
        const str = JSON.stringify(args);
        const shaObj = new jsSHA('SHA-1', 'TEXT');
        shaObj.update(str);
        return shaObj.getHash('HEX');
    }

    public static defValue(a, key, def) {
        if (!a || !a[key]) {
            return def;
        }
        return a[key];
    }

    public static getPercent(a, b, def) {
        if (typeof (a) === 'undefined' || typeof (b) === 'undefined') {
            if (typeof (def) === 'undefined') {
                return 0;
            } else {
                return def;
            }
        }
        let p = parseInt('' + (a / b * 100), 10);
        if (p < 0) {
            p = 0;
        } else if (p > 100) {
            p = 100;
        }
        return p;
    }

    public static xstrMid(str, left, right, tag = '...') {
        if (!str || str.length < (left + right)) {
            return str;
        }
        return str.substr(0, left) + tag + str.substr(0 - right);
    }

    public static formatMoney(s, n) {
        if (n !== 0) {
            n = n > 0 && n <= 20 ? n : 2;
            s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
        } else {
            s = parseInt(s + '', 10) + '';
        }
        const sp = s.split('.');
        const l = s.split('.')[0].split('').reverse(), r = sp.length > 1 ? '.' + sp[1] : '';
        let t = '', i = 0;
        for (; i < l.length; i++) {
            t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? ',' : '');
        }
        return t.split('').reverse().join('') + r;
    }

    public static formatFixed(num, n) {
        const p = Math.pow(10, n);
        return (Math.floor(num * p) / p).toFixed(n);
    }

    public static formatFloat(v, nFixed2?) {
        const fx = nFixed2 ? nFixed2 : 2;
        if (!v) {
            return (0.0).toFixed(fx);
        }
        let vv = parseFloat(v);
        vv = Math.abs(vv);
        if (vv >= 10000.0) {
            if (vv >= 100000000.0) {
                if (vv >= 10000000000000.0) {
                    return 'N';
                }
                return this.formatFixed(vv / 100000000.0, fx) + '亿';
            }
            return this.formatFixed(vv / 10000.0, fx) + '万';
        }
        return this.formatFixed(vv, fx);
    }

    public static formatBitSize(v, nFixed2?) {
        const fx = nFixed2 ? nFixed2 : 2;
        if (!v) {
            return (0.0).toFixed(fx);
        }
        let vv = parseFloat(v);
        vv = Math.abs(vv);
        if (vv >= 1024.0) {
            if (vv >= 1048576.0) {
                if (vv >= 1073741824.0) {
                    if (vv >= 1099511627776.0) {
                        return this.formatFixed(vv / 1099511627776.0, fx) + 'TB';
                    }
                    return this.formatFixed(vv / 1073741824.0, fx) + 'GB';
                }
                return this.formatFixed(vv / 1048576.0, fx) + 'MB';
            }
            return this.formatFixed(vv / 1024.0, fx) + 'KB';
        }
        return this.formatFixed(vv, fx) + 'B';
    }

    public static formatDistance(v, nFixed2?) {
        const fx = nFixed2 ? 0 : 2;
        if (!v) {
            return (0.0).toFixed(fx);
        }
        let vv = parseFloat(v);
        vv = Math.abs(vv);
        if (vv >= 1000.0) {
            return this.formatFixed(vv / 1000.0, 2) + 'km';
        }
        return this.formatFixed(vv, fx);
    }

    public static formatInt(v) {
        return this.formatFloat(v, true);
    }
    public static formatFloatbai(v) {
        return v * 100;
    }

    public static formatNumber(n) {
        n = isNaN(n) ? 0.0 : n;
        const b = parseInt(n, 10).toString();
        const len = b.length;
        if (len <= 3) {
            return b;
        }
        const r = len % 3;
        return r > 0 ? b.slice(0, r) + ',' + b.slice(r, len).match(/\d{3}/g).join(',') : b.slice(r, len).match(/\d{3}/g).join(',');
    }

    public static timeAgo(str) {
        if (!str) {
            return '';
        }
        const langarr = ['前', '年', '月', '周', '日', '小时', '分钟', '秒', '刚刚'];
        const tm = typeof (str) === 'string' ? (new Date(str)).getTime() : str;
        const differ = ((new Date()).getTime() / 1000) - (tm / 1000);
        return AppUtil.timeAgoBySecond(differ, langarr);
    }

    public static timeAgoBySecond(differ, langarr?) {
        langarr = langarr || ['以内', '年', '月', '周', '日', '小时', '分钟', '秒', '1分钟内'];
        if (isNaN(differ)) {
            return '';
        }
        if (differ < 60) {
            return langarr[8];
        }
        const differY = Math.floor(differ / 365.0 / 86400.0);
        const differM = Math.floor(differ / 30.0 / 86400.0);
        const differW = Math.floor(differ / 7.0 / 86400.0);
        const differD = Math.floor(differ / 86400.0);
        const differH = Math.floor(differ / 3600.0);
        const differMin = Math.floor(differ / 60.0);
        const differS = Math.floor(differ);

        if (differY) {
            return differY + langarr[1] + langarr[0];
        } else if (differM) {
            return differM + langarr[2] + langarr[0];
        } else if (differW) {
            return differW + langarr[3] + langarr[0];
        } else if (differD) {
            return differD + langarr[4] + langarr[0];
        } else if (differH) {
            return differH + langarr[5] + langarr[0];
        } else if (differMin) {
            return differMin + langarr[6] + langarr[0];
        } else {
            return differS + langarr[7] + langarr[0];
        }
    }

    public static formatDate(date, fmt) { // author: meizz
        if (typeof (date) !== 'object') {
            date = new Date(date);
        }
        const o = {
            'M+': date.getMonth() + 1, // 月份
            'd+': date.getDate(), // 日
            'h+': date.getHours(), // 小时
            'm+': date.getMinutes(), // 分
            's+': date.getSeconds(), // 秒
            'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
            S: date.getMilliseconds() // 毫秒
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        for (const k of Object.keys(o)) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? o[k] : (('00' + o[k]).substr(('' + o[k]).length)));
            }
        }
        return fmt;
    }

    public static jsonParse(data) {
        try {
            return typeof (data) === 'string' ? JSON.parse(data) : data;
        } catch (e) { }
        return {};
    }

    public static unique(s, cb) {
        const arr = s.sort().join(',,').replace(/(,|^)([^,]+)(,,\2)+(,|$)/g, '$1$2$4').replace(/,,+/g, ',').replace(/,$/, '').split(',');
        cb = cb || ((v) => v);

        for (const i of Object.keys(arr)) {
            arr[i] = cb(arr[i]);
        }
        return arr;
    }

    public static pagination(pageNo, pageSize, array) {
        const offset = (pageNo - 1) * pageSize;
        return (offset + pageSize >= array.length) ? array.slice(offset, array.length) : array.slice(offset, offset + pageSize);
    }

    public static isNumber(n, accurate?) {
        if (accurate) {
            return typeof (n) === 'number' && isFinite(n);
        } else {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }
    }

    public static arrayMerge(...args) {
        let k = 0;
        const res = [];
        for (const i of Object.keys(args)) {
            for (const j of Object.keys(args[i])) {
                if (this.isNumber(j)) {
                    res[k++] = args[i][j];
                } else {
                    res[j] = args[i][j];
                }
            }
        }
        return res;
    }

    public static arrayRemove(arr, ...args) {
        let hv = false;
        const res = [];
        for (const i of Object.keys(arr)) {
            hv = false;
            for (const j in Object.keys(args)) {
                if (i === args[j]) {
                    hv = true;
                    break;
                }
            }
            if (!hv) {
                res.push(arr[i]);
            }
        }
        return res;
    }

    public static inArray(v, arr) {
        if (typeof (arr) !== 'object' || typeof (v) === 'object' || !arr) {
            return false;
        }
        for (const i of Object.keys(arr)) {
            if ('' + arr[i] === '' + v) {
                return true;
            }
        }
        return false;
    }

    public static getNextPage(arr, psz) {
        if (!arr || !psz) {
            return 1;
        }
        return Math.floor(arr.length / psz) + 1;
    }

    public static combineMoreData(old, come, psz) {
        if (!come) {
            return old;
        }
        if (!old || !psz) {
            return come;
        }
        const remove = old.length - (this.getNextPage(old, psz) - 1) * psz;
        for (let i = 0; i < remove; i++) {
            old.pop();
        }
        for (const item of come) {
            old.push(item);
        }
        return old;
    }

    public static parseQueryString(url, onlyParams?): any {
        let str = url;
        const result = {};
        if (!onlyParams) {
            const argo = url.split('?');
            if (argo.length < 2) {
                return result;
            }
            str = argo[1];
        }
        const temp = str.split('&');
        for (const item of temp) {
            const temp2 = item.split('=');
            result[temp2[0]] = decodeURIComponent(temp2[1]);
        }
        return result;
    }

    public static buildQuery(params) {
        if (!params) {
            return '';
        }
        const buf = [];
        for (const key of Object.keys(params)) {
            buf.push(key + '=' + encodeURIComponent(params[key]));
        }
        return buf.join('&');
    }

    // url = AppUtil.updateUrl(url, {export: 1, token: ...})
    public static updateUrl(url, params?) {
        if (!params) {
            return url;
        }
        const argo = url.split('?');
        if (argo.length < 2) {
            return argo[0];
        }

        const temp = argo[1].split('&');
        const p = {};
        for (const item of temp) {
            const t = item.split('=');
            p[t[0]] = decodeURIComponent(t[1]);
        }
        for (const k of Object.keys(params)) {
            p[k] = params[k];
        }

        const query = this.buildQuery(p);
        if (!query) {
            return argo[0];
        }
        return argo[0] + '?' + query;
    }

    public static toMinute(sec, start): string {
        const s = sec - start;
        const vm = Math.floor(s / 3600);
        const vs = '' + Math.floor((s - vm * 3600) / 60);
        let r = '';
        r += ('' + vm).length === 1 ? '0' + vm : vm;
        r += ':' + (vs.length === 1 ? '0' + vs : vs);
        return r;
    }

    public static getPartTime(part) {
        let sec = 0;
        for (const item of part) {
            if (!!(item) && typeof (item) !== 'function' && item.length === 3) {
                sec += (item[2] - item[1]);
            }
        }
        const c = Math.ceil(sec / 60);
        const lbs = [];
        let x = 0;
        let tx = '';
        for (const item of part) {
            if (!!(item) && typeof (item) !== 'function' && item.length === 3) {
                let j = item[1] + x;
                let itx = '';
                let ifirst = true;
                for (; j < item[2]; j += 10800) {
                    itx = this.toMinute(j, item[0]);
                    if (ifirst) {
                        lbs.pop();
                        ifirst = false;
                        if (tx.length > 0) {
                            itx = tx + '/' + itx;
                        }
                    }
                    lbs.push(itx);
                }
                x = j - item[2];
                if (x <= 0) {
                    itx = this.toMinute(j, item[0]);
                    lbs.push(itx);
                }
                tx = itx;
            }
        }

        return { count: c, labels: lbs };
    }

    public static ge(a) {
        return typeof (a) === 'string' ? (document.getElementById ? document.getElementById(a) : null) : a;
    }

    public static parseFloat(a) {
        const r = parseFloat(a);
        if (isNaN(r)) {
            return 0.0;
        }
        return r;
    }

    public static parseInt(a) {
        const r = parseInt(a, 10);
        if (isNaN(r)) {
            return 0;
        }
        return r;
    }


    /**************系统消息 ********/
    public static updateParams(params, fn) {

    }

    public static Rad(d) {
        return d * Math.PI / 180.0; // 经纬度转换成三角函数中度分表形式。
    }

    public static calcDistance(lat1, lng1, lat2, lng2) {
        const radLat1 = this.Rad(lat1);
        const radLat2 = this.Rad(lat2);
        const a = radLat1 - radLat2;
        const b = this.Rad(lng1) - this.Rad(lng2);
        let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
            Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
        s = s * 6378.137; // EARTH_RADIUS;
        s = Math.round(s * 10000) / 10;
        return s;
    }

    public static account(sess) {
        if (typeof (sess.username) !== 'undefined' || sess.username.length > 0) {
            return sess.username;
        } else if (typeof (sess.umobile) !== 'undefined' || sess.umobile.length > 0) {
            return sess.umobile;
        } else if (typeof (sess.uemail) !== 'undefined' || sess.uemail.length > 0) {
            return sess.uemail;
        } else if (typeof (sess.uopenid) !== 'undefined' || sess.uopenid.length > 0) {
            return sess.uopenid;
        }
        return '';
    }

    public static isNum(str) {
        const newstr = str.replace(/[^0-9]/ig, '');
        return newstr;
    }

    public static objectToPairArray(obj) {
        if (!obj) {
            return [];
        }
        const t = [];
        for (const i of Object.keys(obj)) {
            t.push({
                key: i,
                value: obj[i],
            });
        }
        return t;
    }

    // 去掉html中的标签  <div><p>...
    public static stripTags(html) {
        const div = document.createElement('div');
        div.innerHTML = html;
        return (div.textContent || div.innerText);
    }

    /**********************************
     filepathJoin($protect_split_tag, $path1, ...);
    首参数为true : 用于连接网络路径, 可保护$path1为 'http://', 'file:///abc'等路径协议的连续多斜杠
    首参数为false: 用于连接本地路径, 所有路径连续多斜杠都将被清理为单一

    如:
    filepath_join(true, $config['path'], 'a\\\\bc', '//dd/', '123.png');
    filepath_join(false, '//var/a', 'ab//\\c ', '123.png');
    **********************************/
    public static filepathJoin(...args) {
        if (args.length < 2) {
            return '';
        }
        const protect = !!args[0];
        let path1 = '';
        let path2 = '';
        let first = true;
        let skipPos = 0;
        for (const k of Object.keys(args)) {
            if (parseInt(k, 10) === 0) {
                continue;
            }
            let part = args[k].trim();
            if (part === '') {
                first = false;
                continue;
            }
            if (protect && first) {
                let pos = part.indexOf('//');
                if (pos >= 0 && part.length > 2) {
                    // 保护三连杠///, file路径协议有三连杠情况
                    if (part.length > (pos + 3) && part[pos + 2] === '/') {
                        pos++;
                    }
                    skipPos = pos + 2;
                    path1 = part.substr(0, skipPos);
                    part = part.substr(skipPos);
                }
                first = false;
            }
            path2 += '/' + part;
        }
        path2 = path2.replace(/[\/\\]+/g, '/');
        if (path1 === '') {
            return path2;
        }

        // path1有双斜杠时, path2首字符斜杠应该去掉
        if (path2 !== '' && path2[0] === '/') {
            path2 = path2.substr(1);
        }
        return path1 + path2;
    }
    
    public static isEmpty(v, k?) {
        if (v === null || v === false || v === 0 || v === '') {
            return true;
        }
        switch (typeof (v)) {
            case 'object':
                if (k === null || k === '') {
                    return v.length < 1;
                } else {
                    return this.isEmpty(v[k]);
                }
            case 'function':
                return false;
            default:
                return false;
        }
    }
}
