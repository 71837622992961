import { AppState } from '../app.state';
import { LangEn } from './lang-en';
import { LangZhCn } from './lang-zh-cn';

class LangBase {
    $langs = {};
    $langInfo = [];
    $langBase;

    public use(lg) {
        if (this.$langs[lg.type]) {
            return;
        }

        if (!this.$langBase) {
            this.$langBase = lg;
        }
        this.$langs[lg.type] = lg;
        this.$langInfo.push({ type: lg.type, name: lg.name });
    }

    public support() {
        return this.$langInfo;
    }

    public lang(type: string, key: string): string {
        const lg = this.$langs[type] ? this.$langs[type] : this.$langBase;

        let v = lg.lang(key);
        if (v) {
            return v;
        }
        if (this.$langBase.type === lg.type) {
            return '[' + type + '|' + key + ']';
        }
        v = this.$langBase.lang(key);
        if (v) {
            return v;
        }
        return '[' + type + '|' + key + ']';
    }
}

const langBase = new LangBase();
langBase.use(LangEn);
langBase.use(LangZhCn);


export const LANG = (key: string): string => {
    return langBase.lang(AppState.state.lang, key);
};
export const Language = langBase;