// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../assets/logo-white.png");
var ___HTML_LOADER_IMPORT_1___ = require("../../assets/kefuicon.png");
var ___HTML_LOADER_IMPORT_2___ = require("../../assets/sybgt.jpg");
var ___HTML_LOADER_IMPORT_3___ = require("../../assets/company.png");
var ___HTML_LOADER_IMPORT_4___ = require("../../assets/erweima.png");
var ___HTML_LOADER_IMPORT_5___ = require("../../assets/android-kefu.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var code = "<div id=\"page-home\"> <div class=\"ititle\"> <div class=\"ibody\"> <div class=\"ibody-img\"> <img class=\"img2\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" @click=\"jump('/home')\"/> </div> <a class=\"text\" style=\"margin-left:123px\" @click=\"jump('/home')\">首页</a> <a class=\"text\" href=\"http://open.3g3e.com\">开放平台</a> <div class=\"ibody-telimg\"> <img alt=\"\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"/> <div class=\"ibody-tel\">400-133-0001 早8:30-晚17:30</div> </div> </div> </div> <div class=\"body\"> <div class=\"bg\"> <img class=\"img3\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"/> </div> <div class=\"flex2\"> <div class=\"flex2-img\"> <img class=\"img1\" src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"\"> </div> <div class=\"flex2-text\"> 公司简介 </div> </div> <div class=\"flex3\"> 常客云是基于saas平台的会员权益组织；平台整合多家品牌供应商，为政企客户的员工福利、市场营销等数字化升级提供一站式数字化技术供应链服务。 </div> <div class=\"flex4\"> <div class=\"flex4-img\"><img class=\"img4\" src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" alt=\"\"></div> <div class=\"flex4-dl\">识别二维码下载APP</div> <div class=\"flex4-img2\"><img class=\"img5\" src=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\" alt=\"\"></div> <div class=\"flex4-dl2\">400-133-0001</div> </div> <div class=\"flex5\"> <div class=\"flex5-box\"></div> <div class=\"flex5-body\"> <div class=\"flex5-text\"> 辽宁常客云数字科技有限公司 </div> <div class=\"flex5-text\"> <a href=\"https://beian.miit.gov.cn/#/Integrated/index\"> 辽ICP备2021003130号-2 </a> </div> </div> </div> </div> </div>";
// Exports
module.exports = code;