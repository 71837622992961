import Vue from 'vue';
import VueRouter from 'vue-router';
import { PagePlatform } from '../pages/platform/platform';
import { PageHome } from '../pages/home/home';
import { PagePLOpenplatform } from '../pages/platform/openplatform/openplatform';


// 同一path多次push不提示错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes: any = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: PageHome,
        meta: {
            title: '首页'
        }
    },
    // {
    //     path: '/platform/openplatform',
    //     component: PagePLOpenplatform,
    //     meta: { title: '开发平台', icon: 'desktop' }
    // },
   
];
const router = new VueRouter({
    routes
});
router.beforeEach((to, from, next) => {
    // ...
    next();

});
export default router;