
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export const AppState = new Vuex.Store({
    state: {
        // 选中的菜单
        selectkey: '/platform/home',

        // 左侧栏展开与否
        collapsed: screen.width < 752 ? true : false,

        // 登录/session
        isLogin: false,
        sess: null,
        isAdmin: false,

        // 语言
        lang: 'en',

        // old
        seriesLength: 1,
        media_id: -1000,
        msg: -1,

    },
    mutations: {

        changeCollapsed(state, collapsed) {
            state.collapsed = collapsed;
        },

        async setSessionStatus(state, data) {
            state.sess = data.sess;
            state.isLogin = data.isLogin;
            console.log('state.setSessionStatus.isLogin', state.isLogin);
        },

        setSelectedKey(state, data) {
            state.selectkey = data;
        },

        setLang(state, data) {
            state.lang = data;
        },

        // old

        setSeriesLength(state, seriesLength) {
            state.seriesLength = seriesLength;
        },
        setMedia_id(state, mediaId) {
            state.media_id = mediaId;
        },
        setMsgLength(state, msg) {
            state.msg = msg;
        }
    },
});
