import Component from 'vue-class-component';
import { mapState, mapMutations } from 'vuex';
import { sprintf } from 'sprintf-js';
import { BigNumber } from 'bignumber.js';
import { AppUtil } from './app.util';
import { Language } from './lang/lang';
import { AppConfig } from './app.config';
import Vue from 'vue';

@Component({
    computed: {
        ...mapState(['collapsed', 'isLogin', 'isAdmin', 'sess', 'lang']),
    },
    methods: {
        ...mapMutations(['changeCollapsed', 'setSelectedKey']),
    },
})
export class AppVue extends Vue {
    LANG(key: string): string {
        return Language.lang(this.$store.state.lang, key);
    }

    setLang(key) {
        AppConfig.setLang(key);
        console.log(key);
    }

    json(v) {
        return JSON.stringify(v);
    }

    sprintf(...args) {
        const fargs = [];
        for (const item of args) {
            if (typeof (item) !== 'undefined') {
                fargs.push(item);
            }
        }
        if (fargs.length < 2) {
            return '-';
        }
        return sprintf(...fargs);
    }

    numberFormat(v, n = 6) {
        if (!v) {
            return (new BigNumber(0)).toFormat(n);
        }
        return (new BigNumber(v)).toFormat(n);
    }

    numberFixed(v, n = 6) {
        if (!v || v.length === 0 || isNaN(v)) {
            v = '0';
        }
        return (new BigNumber(v)).toFixed(n);
    }

    percent(v) {
        const t = parseFloat(v) * 100.0;
        return isNaN(t) ? '0%' : (t.toFixed(1) + '%');
    }

    num(v) {
        // tslint:disable-next-line: radix
        return parseFloat((parseInt((parseFloat(v) * 100).toString()) / 100).toString());
    }

    date(v, fmt) {
        return AppUtil.formatDate(new Date(v), fmt);
    }

    xstr(v, left, right) {
        return AppUtil.xstrMid(v, left, right);
    }

    isWeiXin() {
        const chk = new RegExp('MicroMessenger', 'i');
        const ua = navigator.userAgent.toLowerCase();
        return chk.test(ua);
    }

    isNotWeiXin() {
        return !this.isWeiXin();
    }

    async hrefTo(path, checkLogin?, checkAdmin?, params?) {
        if (checkLogin && !this.$store.state.isLogin) {
            alert('登录超时');
            this.$router.push('/home');
            this.$store.commit('setSelectedKey', '/home');
            return;
        }
        if (checkAdmin && !this.$store.state.isAdmin) {
            alert('操作失败');
            this.$router.push('/home');
            this.$store.commit('setSelectedKey', '/home');
            return;
        }

        if (params) {
            /*
            // 重编码
            for (const key of Object.keys(params)) {
                if (typeof (params[key]) === 'object') {
                    params[key] = 'o:' + JSON.stringify(params[key]);
                } else if (typeof (params[key]) === 'boolean') {
                    params[key] = 'b:' + (params[key] ? 'true' : 'false');
                } else {
                    params[key] = 's:' + params[key];
                }
            }
            */
        }

        window.scrollTo(0, 0);
        AppConfig.set('selectKey', path);
        this.$router.push({ path, query: params });
        this.$store.commit('setSelectedKey', path);
    }

    needCheckLogin() {
        if (!this.$store.state.isLogin) {
            this.$router.push('/home');
            return false;
        }
        return true;
    }
    strParams(params) {
        if (params === null) {
            return null;
        }
        for (const k of Object.keys(params)) {
            params[k] = '' + params[k];
        }
        return params;
    }
    isEmpty(v, k?) {
        return AppUtil.isEmpty(v, k);
    }
    timeformat(datea){
        const date = new Date(datea);
        const y = date.getFullYear();
        const m = date.getMonth() + 1;
        const mon =  m.toString().padStart(2,'0');
        const d = date.getDate().toString().padStart(2,'0');
        const hh = date.getHours().toString().padStart(2,'0');
        const mm = date.getMinutes().toString().padStart(2,'0');
        const ss = date.getSeconds().toString().padStart(2,'0');
        return `${y}-${mon}-${d} ${hh}:${mm}:${ss}`
    }
}